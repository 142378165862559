<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <planimetry-view-pdf
        v-if="getCurrentType === 'bay-planimetry'"
        :bayJson="pageInfo.bayJson"
        :itineraryInfo="pageInfo.itineraryInfo"
        :filter="pageInfo.filter"
        :modulo="pageInfo.modulo"
        :columns="parseInt(pageInfo.columns)"
        :rows="parseInt(pageInfo.rows)"
        :from="pageInfo.from"
        :to="pageInfo.to"
        :blockItems="pageInfo.blockItems"
        :totalBlock="pageInfo.totalBlock"
        :details="pageInfo.details"
        :legend = "legend"
      />
    </div>
  </transition>
</template>

<script>
import PlanimetryViewPdf from "@/pages/yard-management/pdf/planimetry-view-pdf";
import debounce from 'lodash/debounce';
import { mapState } from "vuex";
import ldb from 'localdata';

//Data
function data() {
  return {
    pageInfo: {
      bayJson: [],
      totalBlock: {
        blockName: '',
        TotalCont20: '',
        TotalCont40: '',
        TotalCont45: '',
        TotalTeus: '',
        details: {}
      },
      from: 0,
      to: 0,
      columns: 5,
      rows: 2,
      blockItems: '',
      type: '',
    },
    docTitle: '',
    UnderDeckJson: [],
    ToDeckJson: [],
    baysJson: [],
    legend: []
  };
}

//Created
function created() {
  // let info = JSON.parse(localStorage.getItem('pageInfo'));
  
  let info = "",
  vm = this;
  ldb.get('pageInfo', async function (value) {
    info = JSON.parse(value);
    ldb.delete('pageInfo');

    if(!info) {
      window.close();
      return;
    }

    vm.pageInfo = {...vm.pageInfo, ...info};

    await vm.getDataFromService(vm.pageInfo.details, vm.pageInfo.view);

    setTimeout(() => {
      window.print();
    }, 250);

    localStorage.removeItem('pageInfo');
    
    window.addEventListener("beforeprint", function(event) {
      // document.title = `FULL CARGO PLAN`;
    });
    window.addEventListener("afterprint", function(event) {
      close();
    });
    
  });
}


async function getDataFromService(details, view) {
await this.$http.ejecutar('GET', 'YardManagementMandatory-list',{ YardId: details.YardId, YardViewId : view })
  .then(response => {
    this.legend = response.data.data || [];
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

//Computed:

function getCurrentType() {
  return this.pageInfo ? this.pageInfo.type : '';
}

export default {
  name: "Reporte-PDF",
  components: {
    PlanimetryViewPdf,
  },
  data,
  created,
  methods: {
    getDataFromService
  },
  computed: {
    getCurrentType,
    ...mapState({
        yardData: state => state.yardManagement.yardData,
        user: (state) => state.auth.user,
    }),
  },
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>